// src/Confirmation.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import liff from "@line/liff";

function Confirmation() {
  const location = useLocation();
  const navigate = useNavigate();

  const bookingData = location.state;

  if (!bookingData) {
    // 如果沒有預約資料，重定向回預約頁面
    navigate("/");
    return null;
  }

  const {
    name,
    phone,
    selectedService,
    time,
    date,
    masseurGender,
    selectedMaster,
    people,
    note,
  } = bookingData;

  const formattedDate = date
    .toLocaleDateString("zh-TW", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");

  const handleConfirm = () => {
    const isNoSpecified = selectedMaster === "no_specified";
    const masseurCode = isNoSpecified ? "" : selectedMaster;
    const displayMaster = isNoSpecified ? "無指定" : selectedMaster;

    const message = `##我要預約/${name}/${phone}/${selectedService}/${time}/${formattedDate}/${masseurGender}/${masseurCode}/${people}/${note || "無"}`;

    liff
      .sendMessages([
        {
          type: "text",
          text: message,
        },
      ])
      .then(() => {
        alert("預約資訊已傳送！");
        liff.closeWindow();
      })
      .catch((error) => {
        console.error("Error sending message", error);
        alert("無法傳送訊息，請稍後再試");
      });
  };

  const handleModify = () => {
    // 導回預約頁面，並傳遞現有的預約資料以便編輯
    navigate("/", { state: bookingData });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg mx-auto">
      <h2 className="text-2xl font-semibold mb-4 text-primary text-center">
        預約確認
      </h2>
      <div className="space-y-4">
        <div>
          <strong>姓名：</strong> {name}
        </div>
        <div>
          <strong>電話：</strong> {phone}
        </div>
        <div>
          <strong>服務項目：</strong>{" "}
          {selectedService}
        </div>
        <div>
          <strong>預約日期：</strong> {formattedDate}
        </div>
        <div>
          <strong>預約時間：</strong> {time}
        </div>
        <div>
          <strong>指定師傅性別：</strong> {masseurGender}
        </div>
        <div>
          <strong>選擇師傅編號：</strong>{" "}
          {selectedMaster === "no_specified" ? "無指定" : selectedMaster}
        </div>
        <div>
          <strong>人數：</strong> {people}
        </div>
        <div>
          <strong>備註：</strong> {note || "無"}
        </div>
      </div>

      {/* 操作按鈕 */}
      <div className="flex justify-between mt-6">
        <button
          onClick={handleModify}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
        >
          修改預約
        </button>
        <button
          onClick={handleConfirm}
          className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-orange-700"
        >
          確認預約
        </button>
      </div>
    </div>
  );
}

export default Confirmation;
