import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import liff from "@line/liff";

function Booking() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [masters, setMasters] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState("");
  const [masseurGender, setMasseurGender] = useState("");
  const [people, setPeople] = useState(1);
  const [note, setNote] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [lineUserId, setLineUserId] = useState("");
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const [isSending, setIsSending] = useState(false); // 新增此狀態來控制按鈕

  useEffect(() => {
    liff.init({ liffId: process.env.REACT_APP_LIFF_ID })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          liff.getProfile().then((profile) => {
            setLineUserId(profile.userId);
          });
        }
      })
      .catch((err) => console.error("LIFF init error", err));

    fetch("https://dianzan-api.hiiihosting.com/api/v1/frontend/masseurs")
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        const availableMasters = data
          .filter((master) => master.status === true)
          .sort((a, b) => parseInt(a.masseur_code) - parseInt(b.masseur_code));
        setMasters([...availableMasters]);
      })
      .catch((error) => {
        console.error("Error fetching masters:", error);
        setMasters([{ id: "default", masseur_code: "無指定" }]);
      });

    fetch("https://dianzan-api.hiiihosting.com/api/v1/frontend/massage-services")
      .then((response) => response.json())
      .then((data) => {
        const availableServices = data.filter(
          (service) => service.status === true
        );
        if (availableServices.length > 0) {
          setServices(availableServices);
        } else {
          alert("請使用聊天室與服務人員預約");
          liff.closeWindow();
        }
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        alert("請使用聊天室與服務人員預約");
        liff.closeWindow();
      });
  }, []);

  const handlePhoneBlur = () => {
    const phonePattern = /^09[0-9]{8}$/;
    if (!phonePattern.test(phone)) {
      setPhoneError("請輸入正確的電話號碼");
    } else {
      setPhoneError("");
    }
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 10; hour <= 23; hour++) {
      times.push(`${String(hour).padStart(2, "0")}:00`);
      times.push(`${String(hour).padStart(2, "0")}:30`);
    }
    return times;
  };

  const isFormComplete = () => {
    return (
      name &&
      phone &&
      phone.length === 10 &&
      selectedMaster &&
      date &&
      time &&
      selectedService &&
      masseurGender &&
      people
    );
  };

  const handleFirstStepSubmit = (e) => {
    e.preventDefault();
    if (isFormComplete()) {
      setIsConfirmStep(true);
    }
  };

  const handleConfirmBooking = () => {
    // 確保按下後不再重複點擊
    setIsSending(true);

    const noteContent = note || "無";
    const isNoSpecified = selectedMaster === "no_specified";
    const masseurCode = isNoSpecified ? "" : selectedMaster;
    const formattedDate = date
      .toLocaleDateString("zh-TW", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    const message = `##我要預約/${name}/${phone}/${selectedService}/${time}/${formattedDate}/${masseurGender}/${masseurCode}/${people}/${noteContent} `;

    liff
      .sendMessages([
        {
          type: "text",
          text: message,
        },
      ])
      .then(() => {
        alert("預約資訊已傳送！");
        liff.closeWindow();
      })
      .catch((error) => {
        console.error("Error sending message", error);
        alert("無法傳送訊息，請稍後再試");
        setIsSending(false); // 若失敗則重新允許點擊
      });
  };

  const handleEditBooking = () => {
    setIsConfirmStep(false);
  };

  return (
    <div className="bg-white p-6 rounded shadow-md w-full max-w-md mx-auto mt-10">
      <h2 className="text-xl font-semibold mb-4 text-primary text-center">
        點讚按摩預約表單
      </h2>

      {!isConfirmStep ? (
        <form onSubmit={handleFirstStepSubmit} className="space-y-4">
          {/* 姓名 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>姓名
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
              focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          {/* 預約日期 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>  預約日期
            </label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
              focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              wrapperClassName="w-full"
              minDate={new Date()}
              dateFormat="yyyy/MM/dd"
              placeholderText="點擊選擇日期"
              required
            />
          </div>

          {/* 預約時間 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>預約時間
            </label>
            <select
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5"
              required
            >
              <option value="" disabled hidden>
                請選擇時間
              </option>
              {generateTimeOptions().map((timeOption) => (
                <option key={timeOption} value={timeOption}>
                  {timeOption}
                </option>
              ))}
            </select>
          </div>

          {/* 師傅性別 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>指定師傅性別
            </label>
            <select
              value={masseurGender}
              onChange={(e) => setMasseurGender(e.target.value)}
              className="h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5"
              required
            >
              <option value="" disabled hidden>
                請選擇性別
              </option>
              <option value="無指定">無指定</option>
              <option value="男">男</option>
              <option value="女">女</option>
            </select>
          </div>

          {/* 師傅編號 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>選擇師傅編號
            </label>
            <select
              value={selectedMaster}
              onChange={(e) => setSelectedMaster(e.target.value)}
              className="h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5"
              required
            >
              <option value="" disabled hidden>
                請選擇師傅
              </option>
              <option value="no_specified">無指定</option>
              {masters.length > 0 &&
                masters.map((master) => (
                  <option key={master.id} value={master.masseur_code}>
                    {`編號 ${master.masseur_code}(${master.gender})`}
                  </option>
                ))}
            </select>
          </div>

          {/* 服務項目 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>服務項目
            </label>
            <select
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
              className="h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5"
              required
            >
              <option value="" disabled hidden>
                請選擇項目
              </option>
              {services.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.title}
                </option>
              ))}
            </select>
          </div>

          {/* 電話 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>電話
            </label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onBlur={handlePhoneBlur}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {phoneError && (
              <p className="text-red-500 text-sm mt-1">{phoneError}</p>
            )}
          </div>

          {/* 人數 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
            <span className="text-red-500">*</span>人數
            </label>
            <input
              type="number"
              value={people}
              onChange={(e) => setPeople(e.target.value)}
              min="1"
              max="10"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          {/* 備註 */}
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">
              備註
            </label>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm 
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              rows="3"
              placeholder="可選填寫備註..."
            ></textarea>
          </div>

          {/* 預約按鈕 */}
          <button
            type="submit"
            className={`font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center ${
              isFormComplete()
                ? "bg-primary text-white hover:bg-orange-700"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            disabled={!isFormComplete()}
          >
            預約
          </button>
        </form>
      ) : (
        // 確認預約資料畫面
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-800 text-center mb-4">確認預約資料</h3>
          <div className="bg-gray-50 p-4 rounded-lg space-y-2 border border-gray-200">
            <p><strong>姓名：</strong>{name}</p>
            <p><strong>電話：</strong>{phone}</p>
            <p><strong>預約日期：</strong>{date && date.toLocaleDateString("zh-TW")}</p>
            <p><strong>預約時間：</strong>{time}</p>
            <p><strong>師傅性別：</strong>{masseurGender}</p>
            <p><strong>師傅編號：</strong>{selectedMaster === "no_specified" ? "無指定" : selectedMaster}</p>
            <p><strong>服務項目：</strong>
              {services.find((s) => s.id === parseInt(selectedService))?.title || ""}
            </p>
            <p><strong>人數：</strong>{people}</p>
            <p><strong>備註：</strong>{note || "無"}</p>
          </div>

          <div className="flex justify-between mt-6">
            <button
              onClick={handleEditBooking}
              className="bg-gray-300 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-400"
            >
              修改預約
            </button>
            <button
              onClick={handleConfirmBooking}
              className="bg-primary text-white rounded-lg px-4 py-2 hover:bg-orange-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isSending} // 根據 isSending 狀態來控制按鈕
            >
              確認預約
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Booking;
