import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Booking from "./pages/Booking"; // 預約頁面
import Confirmation from "./pages/Confirmation";
function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100 p-4">
        {/* 導航列 */}
     

        {/* 路由配置 */}
        <Routes>
          <Route path="/" element={<Booking />} />
          <Route path="/confirmation" element={<Confirmation />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
